<template>
  <div class="contact-section">

    <LetsChat></LetsChat>
    <Contact backgroundColor="#2c2c2c"
             displayContact="none">
    </Contact>
    <div class="main-background">
      <div id="google-maps" :style="{ backgroundImage: 'url(' + mapLocation + ')'}">
        <div class="change-map">
          <p class="change-address">
            <a v-bind:href="locationSubsidiary" target="_blank">
              Sibiu, Strada Lunga 9, Sibiu 550200
            </a>
          </p>
          <button id="change-map-avrig" @click="changeMapAvrig">Avrig</button>
          <button id="change-map-sibiu" @click="changeMapSibiu">Sibiu</button>

        </div>
      </div>
      <div class="arrow-right-flume">
        <div class="contact-info">

          <a id="contact" v-bind:href="'mailto:' + contact">{{ contact }}</a>
          <a v-bind:href="contactPhone">{{ contactPhone }}</a>
          <div class="contact-info_line-separator"></div>
          <a id="development" v-bind:href="'mailto:' + development">{{ development }}</a>
          <a id="design" v-bind:href="'mailto:' + design">{{ design }}</a>
          <a id="support" v-bind:href="'mailto:' + support">{{ support }}</a>
          <a id="hr" v-bind:href="'mailto:' + hr">{{ hr }}</a>
        </div>
      </div>

    </div>


    <Footer color="#181818">
      <DropDownFooter colorMobile="#181818">
        <DropDown subMenuColor="#181818"></DropDown>
      </DropDownFooter>
    </Footer>
  </div>
</template>

<script>

import $ from "jquery";
import flumeTriangle from "../assets/contact/right-flume-triangle.webp";
import mapSibiu from "../assets/contact/sibiu.webp";
import mapAvrig from "../assets/contact/avrig.webp";
import Footer from "../components/Home/Footer";
import DropDownFooter from "../components/FooterMobile/DropDownFooter";
import DropDown from "../components/FooterMobile/DropDown";
import LetsChat from "../components/LetsChat";
import Contact from "../components/Home/Contact";

export default {
  name: 'ContactPage',
  components: {LetsChat, DropDown, DropDownFooter, Footer, Contact},

  data() {
    return {
      locationSubsidiary: 'https://www.google.com/maps/place/Strada+Lungă+9,+Sibiu,+România/@45.8023129,24.1414053,17z/data=!3m1!4b1!4m5!3m4!1s0x474c67eada002271:0xca6cdedc6ecdb9f4!8m2!3d45.8023092!4d24.143594?hl=ro-RO',
      contactPhone: 'tel:+40-742-172-320',
      backgroundFlume: flumeTriangle,
      contact: 'contact@scriptics.ro',
      hr: 'hr@scriptics.ro',
      development: 'development@scriptics.ro',
      design: 'design@scriptics.ro',
      support: 'support@scriptics.ro',
      mapLocation: mapSibiu,
    }
  },

  methods: {
    changeMapSibiu() {
      $("#google-maps").css("opacity", 0),
          $("#google-maps").css("background-size", "180%"),
          $(".change-map #change-map-sibiu").css("background", "#2c2c2c"),
          $(".change-map #change-map-sibiu").css("color", "#fafafa"),
          $(".change-map #change-map-avrig").css("background", "#fafafa"),
          $(".change-map #change-map-avrig").css("color", "#2c2c2c"),
          $(".change-address").html("Sibiu <br/> Strada Lunga 9, Sibiu 550200"),
          $(".change-map .change-address").text("Sibiu, Strada Lunga 9, Sibiu 550200"),
          $("#google-maps").animate({"background-size": "140%", opacity: 1}, 1500)
      this.mapLocation = mapSibiu;
    },
    changeMapAvrig() {
      $("#google-maps").css("opacity", 0),
          $("#google-maps").css("background-size", "180%"),
          $(".change-map #change-map-sibiu").css("background", "#fafafa"),
          $(".change-map #change-map-sibiu").css("color", "#2c2c2c"),
          $(".change-map #change-map-avrig").css("background", "#2c2c2c"),
          $(".change-map #change-map-avrig").css("color", "#fafafa"),
          $(".change-address").html("Avrig <br/> Strada Lazar Gheorghe 2A, Sibiu"),
          $(".change-map .change-address").text("Avrig, Strada Lazar Gheorghe 2A, Sibiu"),
          $("#google-maps").animate({"background-size": "140%", opacity: 1}, 1500)

      this.mapLocation = mapAvrig;
    }
  },

}
</script>

<style scoped lang="scss">
$hr-color: #3a3a3a;
$development-color: #2b98c7;
$design-color: #c22b47;
$support-color: #407841;
.contact-section {
  background-color: rgb(44, 44, 44);

  h1 {
    text-align: center;
  }

}

.contact-info {
  position: relative;
  overflow: hidden;
  padding-top: 10%;
  margin-right: 32px;

  text-align: right;

  &_title {
    font-size: 100px;
    font-weight: 400;
    color: #2d98c8;
  }

  &_line-separator {
    width: 75%;
    height: 1px;
    align-self: stretch;
    background-color: #2d98c8;
    position: absolute;
    right: 0;
  }

  a {
    display: block;
    font-size: 20px;
    font-weight: 400;
    color: #fafafa;
    text-align: right;
    padding: 10px 0 10px 0;
    text-decoration: none;

    &:nth-of-type(1) {
      padding-bottom: 25px;
    }

    &:nth-of-type(2) {
      padding-top: 25px;
    }

    &:hover {
      transition-delay: .2s;
      opacity: .7;
    }

  }

  #contact {
    color: #fafafa;
  }

  #hr {
    color: #fafafa;
  }

  #development {
    color: $development-color;
  }

  #design {
    color: $design-color;
  }

  #support {
    color: $support-color;
  }

}

.contact-section {
  display: block;
}

.main-background {
  overflow: hidden;
}

.arrow-right-flume {
  width: 30%;
  float: left;
  background-repeat: no-repeat;
  background-size: cover;
  height: 65vh;
  min-height: 500px;
  padding-left: 10px;
  padding-right: 10px;
  background: rgb(44, 44, 44);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1a6bb1", endColorstr="#91293e", GradientType=1);

}

#google-maps {
  width: 70%;
  float: left;
  height: 65vh;
  min-height: 500px;
  z-index: 0;
  background: center center no-repeat;
  background-size: cover;
  position: relative;
}

.contact-content {
  width: 100%;
  color: #fafafa;
  max-height: 36em;
}

.maps {
  height: 400px;
}

.contact-info {
  position: relative;
}

.change-address a {
  text-decoration: none;
  color: #fafafa;
}

.change-map {
  background: #91918f;
  padding: 15px;
  width: 100%;
  height: 68px;
  text-align: right;
  opacity: .8;
  position: absolute;
  bottom: 0;
}

.change-map div {
  float: left;
  margin-right: 30%;
}

.change-map button, .change-map p {
  display: table;
  vertical-align: middle;
  float: right;
  line-height: 2.5;
  padding-left: 20px;
  padding-right: 20px;
  font-style: italic;
  font-family: "Heebo", sans-serif;
}

.change-map p {
  color: #fafafa;
}

.change-map #change-map-sibiu,
.change-map #change-map-avrig {
  z-index: 101;
  position: relative;
  cursor: pointer;
  padding-right: 12px;
  padding-left: 12px;
}

.change-map #change-map-sibiu {
  background: #2c2c2c;
  color: #fafafa;
  border: 0;
  border-radius: 15px 0 0 15px;
}

.change-map #change-map-avrig {
  background: #fafafa;
  color: #2c2c2c;
  border: 0;
  border-radius: 0 15px 15px 0;
}

.contact-items__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: center;
  align-items: flex-end;
  -webkit-box-pack: right;
  -ms-flex-pack: right;
  justify-content: flex-end;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  float: right;
  padding: 6vh;
  position: relative;
}

.contact-items {
  display: inline-block;
  z-index: 5;
  color: #3a3a3a;
  margin-top: -12em;
}

.contact-content li {
  width: 100%;
  position: relative;
  float: left;
  font-size: 22px;

  &:hover {
    cursor: pointer;
    opacity: .5;
    transition-delay: .5s;
  }
}

.contact-content li.contact-items-email::before,
.contact-content li.contact-items-phone::before {
  position: absolute;
  left: -71px;
  bottom: -4px;
}

.contact-content li.contact-items-email,
.contact-content li.contact-items-phone,
.contact-content li.contact-items-hr,
.contact-content li.contact-items-development,
.contact-content li.contact-items-design,
.contact-content li.contact-items-support {
  padding-left: 55%
}

.contact-items-line-separator {
  width: 44%;
  height: 1px;
  margin-top: 97px;
  background-color: #2d98c8;
  position: absolute;
  left: 55%;
}

#hr {
  color: #3a3a3a;
}

#development {
  color: #2b98c7;
}

#design {
  color: #b71334;
}

#support {
  color: #0a8c3d;
}

.contact-items li {
  position: relative;
  margin-bottom: 25px;
  float: left
}

.contact-items p.contact-text {
  position: relative;
  height: 75px;
  max-width: 300px;
  display: block;
  float: left;
  text-align: right;
  padding-top: 12px;
  line-height: 1.5;
  color: #2c2c2c;
}

.contact-items p.contact-text::before {
  position: absolute;
  content: " ";
  line-height: 0;
  display: block;
  height: 68px;
  width: 68px;
  top: 0;
  background-size: contain;
}

.contact-content ul a {
  color: #2c2c2c;
  text-decoration: none;
}

.form-section {
  clear: both;
  min-height: 85vh;
  background-color: #2c2c2c;
  padding-top: 151px;
}

.form-section-container {
  width: 40%;
  display: flex;
  margin-left: 17%;
  justify-content: center;
  align-items: center;
}

.contact-form {
  width: 100%;
  background-color: #2c2c2c;
  line-height: 30px;
  padding-bottom: 100px;
  font-size: 12px;
}

.form-bottom {
  padding: 10vh 20px;
  text-align: left;
  z-index: 1048;
}

.clearfix {
  display: inline-block;
}

.form-group {
  margin-bottom: 25px;
  margin-left: 7em;
}

.form-section .form-groups.inquire-check {
  position: relative;
}

.contact-name, .contact-email, .contact-subject, .contact-message {
  height: 35px;
  max-width: 700px;
  margin: 0;
  padding-left: 5px;
  vertical-align: middle;
  border: 1px solid #2d98c8;
  border-bottom: 2px solid #2d98c8;
  font-family: "Myriad Hebrew", Myriad Hebrew, "Myriad Pro", Myriad Hebrew;
  font-size: 17px;
  font-weight: 900;
  line-height: 50px;
  color: #000000;
  background-color: #fafafa;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -o-transition: all .3s;
  -moz-transition: all .3s;
  -webkit-transition: all .3s;
  -ms-transition: all .3s;
  transition: all .3s;
}

.inquire-check input {
  display: none;
}

.inquire-check label {
  display: inline-block;
  text-align: center;
  color: #ababab;
  font-size: 25px;
  cursor: pointer;
}

.inquire-check label span {
  background-color: #ababab;
  height: 18px;
  text-align: center;
  width: 18px;
  transform: rotate(45deg);
  display: inline-block;
  border: 1px solid #2d98c8;
  margin-right: 8px;
  margin-left: 10px;
  font-size: 20px;
}

#contact form span {
  color: #2d98c8;
}

.inquire-check label span span {
  background-color: #2b98c7;
  height: 10px;
  width: 14px;
  transform: rotateY(45deg);
  display: inline-block;
  opacity: 0;
  margin-bottom: 8px;
  margin-left: 1px;
}

.center {
  margin-left: 13em;
}

#design-check:checked + label span span, #development-check:checked + label span span, #support-check:checked + label span span, #hr-check:checked + label span span {
  opacity: 1;
}

.form-group__content-label {
  text-align: right;
  width: 14%;
  display: flex;
}

.form-group_label {
  font-size: 30px;
  color: #ababab;
  font-weight: 900;
  right: 5em;
  top: 1em;
  position: relative;
}

.form-group_choose {
  font-size: 25px;
  text-align: left;
  color: #ababab;
}

.form-control {
  display: block;
  width: 80%;
}

#choose-btn-label {
  color: #ababab;
  padding: 10px 20px;
  width: 100px;
  line-height: 1;
}

.contact-content input[type=file] {
  display: inline-block;
}

#contact form .choose-btn {
  width: 20%;
  height: 30px;
  border: none;
}

.choose-btn {
  width: .1px;
  height: .1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inquire-check label span {
  text-align: center;
}

#contact form p {
  color: grey;
  font-style: italic;
  font-size: 16px;
  width: 100%;
  clear: both;
  display: block;
  font-family: "Myriad Hebrew", Myriad Hebrew, "Myriad Pro", Myriad Hebrew;
}

#contact form span {
  color: #2d98c8;
}

.contact-content input[type=text], .contact-content textarea, .contact-content textarea.form-control {
  height: 44px;
  max-width: 650px;
  margin: 0;
  padding-left: 5px;
  vertical-align: middle;
  border-bottom: 3px solid #2d98c8;
  background: 0 0;
  font-family: "Myriad Hebrew", Myriad Hebrew, "Myriad Pro", Myriad Hebrew;
  font-size: 16px;
  font-weight: 400;
  line-height: 50px;
  color: #fff;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -o-transition: all .3s;
  -moz-transition: all .3s;
  -webkit-transition: all .3s;
  -ms-transition: all .3s;
  transition: all .3s;
}

.button-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 2vh 0;
  width: 80%;
}

i {
  font-size: 20px;
  color: transparent;
  z-index: 99;
  background: #921abc;
  -webkit-background-clip: text;
  vertical-align: middle;
}

#contact form input[type=submit] {
  display: inline-block;
  width: 23%;
  padding: 20px 20px;
  cursor: pointer;
  vertical-align: middle;
  font-family: "Myriad Hebrew", Myriad Hebrew, "Myriad Pro", Myriad Hebrew;
  line-height: 46px;
  font-weight: 900;
  font-size: 30px;
}

.button-section_submit {
  color: #2f98c8;
  //  background: linear-gradient(150deg, #2f98c8 53%, #4977c6 57%, #921abc 65%);
  //  -webkit-background-clip: text;
  //  -webkit-text-fill-color: transparent;
}

.button-section_submit:hover,
.fas fa-chevron-right:hover {
  opacity: .6;
}

@media only screen and (max-width: 1990px) {

  #contact form input[type=submit] {
    width: 30%;
  }
  .contact-items__content {
    padding: 12vh 0 0 0;
    width: 50%;
  }


}


@media screen and (max-width: 1024px) {
  .form-section-container {
    width: 55%;
  }

  form-section {
    height: 48vh;
  }
  .contact-content li {
    font-size: 20px;
  }
  .contact-items__content {
    width: 48%;
  }

  .contact-items {
    margin-top: -20em;
  }

}

@media only screen and (max-width: 768px) {
  .change-map button, .change-map p {
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px

  }
  #google-maps {
    width: 100%;
  }
  .contact-info_title {
    font-size: 60px;
    padding-top: 20px;
  }
  .arrow-right-flume {
    width: 100%;

  }
  .maps {
    height: 280px;
  }

  .form-section-container {
    width: 80%;
  }

  .contact-content li.contact-items-email, .contact-content li.contact-items-phone, .contact-content li.contact-items-hr, .contact-content li.contact-items-development, .contact-content li.contact-items-design, .contact-content li.contact-items-support {
    padding-left: 35%;
  }
}

@media only screen and (max-width: 425px) {
  .form-group_label {
    right: 0;
    top: 0;
  }
  .form-section-container {
    width: 100%;
    margin-left: 0;
  }
  .form-control {
    width: 100%;
  }
  .form-group {
    margin-bottom: 40px;
    margin-left: 0;
  }
  .inquire-check label {
    font-size: 17px;
  }
  #google-maps {
    height: 25vh;
    min-height: 430px;
  }
  .button-section {
    width: 100%;
  }
  #contact form input[type=submit] {
    width: 45%;
  }

}

</style>
